import { defineStore } from "pinia";
import { ref } from "vue";

import { useSessionStore } from "../stores/session.js";

export const useRolesStore = defineStore("roles", () => {
  const roles = ref([]);
  const role = ref({});

  const useSession = useSessionStore();
  const { getToken } = useSession;

  const getRoles = async () => {
    try {
      getToken();
      const res = await fetch(
        "https://backsigede.puertocoquimbo.com/api/v1/roles/",
        {
          headers: {
            Authorization: `Bearer ${useSession.token}`,
          },
        }
      );
      const resJSON = await res.json();
      roles.value = resJSON.body;
    } catch (error) {
      console.log(error);
    }
  };

  const getRole = async (role_id) => {
    try {
      getToken();
      const res = await fetch(
        `https://backsigede.puertocoquimbo.com/api/v1/roles/${role_id}`,
        {
          headers: {
            Authorization: `Bearer ${useSession.token}`,
          },
        }
      );
      const resJSON = await res.json();
      role.value = resJSON.body;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    role,
    roles,
    getRoles,
    getRole,
  };
});
