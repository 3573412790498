import { usePreAdmissionStore } from "@/stores/preadmissions";
import { useSessionStore } from "@/stores/session";
import { useRolesStore } from "@/stores/roles";
import { useUserRolesStore } from "@/stores/userroles";
import { useRouter } from "vue-router";

const checkingPreadmission = async (pradmissionId) => {
  const usePreadmission = usePreAdmissionStore();
  const { getPreadmission } = usePreadmission;
  const preadmission = await getPreadmission(pradmissionId);
  return preadmission;
};

const getCurrentUserRoles = async () => {
  const useSession = useSessionStore();
  const { ping } = useSession;

  const useRoles = useRolesStore();
  const { getRole } = useRoles;

  const useUserRoles = useUserRolesStore();
  const { getUserRoles } = useUserRoles;

  const userRoles = new Set();

  await ping();

  await getUserRoles(useSession._user.user_id);

  await Promise.all(
    useUserRoles.user_roles.map(async (role) => {
      await getRole(role.role_id);
      userRoles.add(useRoles.role.name);
    })
  );
  return Array.from(userRoles);
};

const filterUser = (userRoles, acceptedRoles) => {
  if (Array.isArray(acceptedRoles) && acceptedRoles.length > 0) {
    return userRoles.some((role) => acceptedRoles.includes(role));
  } else {
    return false;
  }
};

const authGuard = (to, from, next) => {
  const token = localStorage.getItem("token");

  if (!token) {
    next(false);
    const router = useRouter();
    router.push("/");
  } else {
    next();
  }
};

const preadmissionGuard = async (to, from, next) => {
  const preadmission = await checkingPreadmission(to.params.id);
  if (!preadmission) {
    next({ name: "login" });
  } else {
    next();
  }
};

const rolesGuard = async (to, from, next) => {
  const requiredRoles = to.meta.roles;
  const userRoles = await getCurrentUserRoles();

  if (filterUser(userRoles, requiredRoles)) {
    next();
  } else {
    next({ name: "home" });
  }
};

const statusGuard = async (to, from, next) => {
  const useSession = useSessionStore();
  const { ping } = useSession;
  await ping();
  if (useSession._user.user_status === true) {
    next();
  } else {
    next(false);
    const router = useRouter();
    router.push("/");
  }
};

export { authGuard, preadmissionGuard, rolesGuard, statusGuard };
