import { createRouter, createWebHistory } from "vue-router";
import {
  authGuard,
  preadmissionGuard,
  statusGuard,
  rolesGuard,
} from "./NavigationGuards";

const routes = [
  {
    path: "/",
    component: () => import("@/views/AuthView.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("../views/LoginView.vue"),
      },
      {
        path: "/preadmission",
        name: "preadmission",
        component: () => import("../views/CheckPreAdmissionView.vue"),
      },
      {
        path: "/preadmission/:id",
        name: "newuser",
        component: () => import("../views/PreadmissionToUser.vue"),
        beforeEnter: preadmissionGuard,
      },
    ],
  },
  {
    path: "/app",
    name: "app",
    component: () => import("../views/AppView.vue"),
    beforeEnter: [authGuard, statusGuard],
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/HomeView.vue"),
        beforeEnter: [authGuard, statusGuard],
      },
      {
        path: "management",
        meta: {
          roles: ["Administrador"],
        },
        beforeEnter: [authGuard, rolesGuard, statusGuard],
        children: [
          {
            path: "users",
            name: "Users",
            component: () => import("../views/ManageUsersView.vue"),
            meta: {
              roles: ["Administrador"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "skills",
            name: "skills",
            component: () => import("../views/ManageSkillsView.vue"),
            meta: {
              roles: ["Administrador"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "preadmissions",
            component: () => import("../views/ManagePreAdmissions.vue"),
            meta: {
              roles: ["Administrador"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
        ],
      },
      {
        path: "user",
        children: [
          {
            path: "profile",
            component: () => import("../views/ProfileUserView.vue"),
            beforeEnter: [authGuard, statusGuard],
          },
          {
            path: "edd",
            component: () => import("../views/ManageUserEvaluationsView.vue"),
            meta: {
              roles: ["Colaborador", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "goals",
            component: () => import("../views/UserGoalsView.vue"),
            meta: {
              roles: ["Colaborador", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "goals/:id",
            component: () => import("../views/ManageChecksView.vue"),
            meta: {
              roles: [
                "Colaborador",
                "Jefe de área",
                "Gerente",
                "Auditor",
                "Administrador",
              ],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "results",
            component: () => import("../views/UserResultsView.vue"),
            meta: {
              roles: ["Colaborador", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
        ],
      },
      {
        path: "forms",
        beforeEnter: [authGuard, statusGuard],
        children: [
          {
            path: "",
            component: () => import("../views/ManageFormsView.vue"),
            meta: {
              roles: ["Administrador", "Gerente", "Auditor", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "new",
            component: () => import("../views/FormView.vue"),
            meta: {
              roles: ["Gerente", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: ":id",
            component: () => import("../views/FormDetailsView.vue"),
            meta: {
              roles: ["Administrador", "Gerente", "Auditor", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
        ],
      },
      {
        path: "edd",
        beforeEnter: [authGuard, statusGuard],
        children: [
          {
            path: "",
            component: () => import("../views/ManageEvaluationsView.vue"),
            meta: {
              roles: ["Administrador", "Gerente", "Auditor", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: ":id",
            component: () => import("../views/EDDView.vue"),
            meta: {
              roles: ["Gerente", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "details/:id",
            component: () => import("../views/EDDDetailsView.vue"),
            meta: {
              roles: [
                "Administrador",
                "Gerente",
                "Auditor",
                "Jefe de área",
                "Colaborador",
              ],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
        ],
      },
      {
        path: "global/results",
        component: () => import("../views/EPCOResults.vue"),
        meta: {
          roles: ["Administrador", "Gerente", "Auditor", "Jefe de área"],
        },
        beforeEnter: [authGuard, rolesGuard, statusGuard],
      },
      {
        path: "audit",
        children: [
          {
            path: "progress",
            component: () => import("../views/ProgressView.vue"),
            meta: {
              roles: ["Administrador", "Gerente", "Auditor", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "goals/:user_id",
            component: () => import("../views/UserGoalsView.vue"),
            meta: {
              roles: ["Administrador", "Gerente", "Auditor", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
          {
            path: "goals/:user_id/:id",
            component: () => import("../views/ManageChecksView.vue"),
            meta: {
              roles: ["Administrador", "Gerente", "Auditor", "Jefe de área"],
            },
            beforeEnter: [authGuard, rolesGuard, statusGuard],
          },
        ],
        beforeEnter: [authGuard, statusGuard],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
